<template>
    <!-- 头部内容 -->
    <div class="flexCenter">
        <!-- 图片 -->
        <div class="picFlex" v-if="detalis&&detalis.value&&detalis.value.header_logo">
            <el-image
                
                style="width: 94px; height: 32px"
                :src="detalis&&detalis.value&&detalis.value.header_logo||''"
                fit="fill"
            >
            
            </el-image>
        </div>
        <!-- 文字 -->
        <div>
            <span class="nameTitle">{{detalis&&detalis.value&&detalis.value.header_name||detalisProps&&detalisProps.company&&detalisProps.company.name||''}}</span>
        </div>
    </div>
</template>

<script>
export default {
    props:['detalis','detalisProps'],
    data() {
        return {
            
        }
    },
    created() {
        console.log(this.detalis)
    },
};
</script>

<style lang="scss" scoped>
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  .picFlex {
      margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nameTitle{
color: rgba(51, 51, 51, 100);
font-size: 28px;
text-align: left;
font-family: SourceHanSansSC-regular;
  }
}
</style>